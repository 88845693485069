import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

const Card = ({
  projectLink,
  deployUrl,
  repoUrl,
  title,
  skills,
  description,
  image,
}) => (
  <Container className="projects">
    <Content>
      <Title>
        {projectLink ? (
          <Link className="underline-light" to={projectLink}>
            {title} ↗{" "}
          </Link>
        ) : (
          <a
            className="underline-light"
            href={deployUrl || repoUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {title} ↗{" "}
          </a>
        )}
      </Title>
      <SkillsSubtitle>{skills}</SkillsSubtitle>
      <Description>{description}</Description>
      <div>
        {projectLink && (
          <Description>
            <Link className="underline-light" to={projectLink}>
              View story ↗{" "}
            </Link>
          </Description>
        )}
        {deployUrl && (
          <Description>
            <a
              className="underline-light"
              href={deployUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              View deployed app ↗{" "}
            </a>
          </Description>
        )}
        {repoUrl && (
          <Description>
            <a
              className="underline-light"
              href={repoUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              View github repo ↗{" "}
            </a>
          </Description>
        )}
      </div>
    </Content>
    {projectLink ? (
      <Link to={projectLink}>
        <Image src={image}></Image>
      </Link>
    ) : (
      <a
        className="underline-light"
        href={deployUrl || repoUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Image src={image}></Image>
      </a>
    )}
  </Container>
);

export default Card;

const Container = styled.div`
  color: #f4f4f4;
  /* margin-top: 24px; */
  /* padding-top: 32px; */
  margin: 128px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background: #1f1f1f;
  width: 860px;

  @media (max-width: 960px) {
    flex-direction: column;
  }

  @media (max-width: 400px) {
    align-items: center;
    max-width: 300px;
    margin: 48px 0 0 0;
    padding: 16px;
  }
`;

const Image = styled.img`
  max-width: 200px;
  border-radius: 8px;
  transition: 0.6s cubic-bezier(0.2, 0.8, 0.2, 1);

  @media (max-width: 400px) {
    max-width: 300px;
    margin: 10px;
  }
`;

const Content = styled.div`
  width: 760px;
  font-size: 20px;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @media (max-width: 960px) {
    order: -1;
  }
  @media (max-width: 400px) {
    /* unset the height, to allow card to grow as necessary 
    and avoid text spilling over */
    height: initial;
    max-width: 300px;
    margin: 10px;
    font-size: 16px;
    font-weight: 400;
    order: 1;
  }
`;

const Title = styled.h3`
  margin: 0;
  font-size: 20px;
  @media (max-width: 400px) {
    font-size: 24px;
  }
`;

const SkillsSubtitle = styled.p`
  margin: 0;
  font-size: 16px;
  color: rgba(244, 244, 244, 0.5);
  @media (max-width: 400px) {
  }
`;

const Description = styled.p`
  line-height: 28px;
  width: 650px;
  @media (max-width: 400px) {
    max-width: 300px;
  }
`;
