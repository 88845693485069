import React from "react";
import styled from "styled-components";
import Card from "../components/Card";
import mastery from "../images/mastery-placeholder-600x600.png";
import notAim from "../images/not-aol-300x300.png";
import cat from "../images/pixel-cat-300x300.png";
import petlee from "../images/petlee2-300x300.png";

const Projects = () => (
  <ProjectContainer>
    <Card
      projectLink="/not-aim"
      deployUrl="https://mgc3.github.io/p4-chat-client/#/"
      repoUrl="https://github.com/MGC3/p4-chat-client"
      title="Not AOL Instant Messenger"
      skills="React, Javascript, Socket.IO, Express, MongoDB, Styled Components"
      description="A chat app inspired by AOL Instant Messenger and Windows XP nostalgia. Features multiple chatrooms and realtime messaging, complete with an interactive desktop-themed environment."
      image={notAim}
    />
    <Card
      // projectLink="/orange-cat"
      deployUrl="https://vigilant-hugle-84a7b7.netlify.com/"
      repoUrl="https://github.com/MGC3/orange-cat-or-not-client"
      title="Orange Cat or Not Orange Cat"
      skills="TensorFlowjs, Keras, Machine Learning, React, Javascript, Python"
      description="An app that identifies whether an image is an orange cat or not an orange cat. Inspired by Silicon Valley's Not Hotdog app."
      image={cat}
    />
    <Card
      // TODO: create case study when done
      // projectLink="/project1"
      title="Mastery (coming soon!)"
      repoUrl="https://github.com/MGC3/mastery"
      skills="React Native, Redux, Firebase"
      description="An iOS app that helps you set and track mastery experiences (goals just outside of your comfort zone that allow you to grow) and helps you achieve them by breaking them down into smaller steps. "
      image={mastery}
    />
    <Card
      // projectLink="/project1"
      deployUrl="https://mgc3.github.io/p2-pet-client/"
      repoUrl="https://github.com/MGC3/p2-pet-client"
      title="Petlee"
      skills="Javascript, Chart.js, Ruby, Ruby on Rails, PostgreSQL, jQuery, Material Design"
      description="A weight tracking app for your pets. "
      image={petlee}
    />
    {/* <Card
      // projectLink="/project1"
      repoUrl="https://github.com/MGC3/"
      title="More Projects"
      skills="Javascript, jQuery, Express, MongoDB, Ruby, Ruby on Rails, PostgreSQL"
      description="See all of my other work. Includes several full-stack projects such as Tic Tac Toe, a pet weight tracking app, and more."
      image={mango}
    /> */}
  </ProjectContainer>
);

export default Projects;

const ProjectContainer = styled.div`
  margin: 0 auto;
  max-width: 700px;
  margin-bottom: 64px;
  margin-left: 15%;

  @media (max-width: 400px) {
    margin: 0;
    margin-top: 128px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
