import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import Projects from "../components/Projects";
import Particles from "react-particles-js";
import particlesConfig from "../config/particles";

// TODO: return back to normal by renaming this file to 'index.js'.
// Don't forget to change "Hero-old.js" as well.
const IndexPage = () => {
  const options = {
    speed: 300,
    minDuration: 250,
    maxDuration: 3000,
    // DOM element to scroll, default window
    // Pass a reference to a DOM object
    // Example: document.querySelector('#element-to-scroll'),
    cancelOnUserAction: true,
  };

  return (
    <Layout>
      <SEO title="Projects" />
      {/* <Particles className="particles" params={particlesConfig} /> */}
      <Navbar />
      <div className="container-test">
        <Hero />
      </div>
      <div className="container-test2">
        <Projects />
      </div>
    </Layout>
  );
};

export default IndexPage;
